import { Routes } from '@angular/router';

export const docsRoutes: Routes = [
  {
    loadComponent: () => import('./docs-page/docs-page.component').then(m => m.DocsPageComponent),
    path: '',
    providers: [],
  },
  {
    loadComponent: () => import('./docs-page/docs-page.component').then(m => m.DocsPageComponent),
    path: ':id',
    providers: [],
  },
];
