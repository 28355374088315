<p-menu
  [model]="menuItems"
  styleClass="docs-menu"
  [ngClass]="{ 'docs-menu--folded': isMobileMenuFolded, 'docs-menu--unfolded': !isMobileMenuFolded }">
  <ng-template pTemplate="start">
    <div class="flex align-items-center justify-content-center my-3">
      <img src="/assets/fizjopro-logo-horizontal-lightbg-color.svg" height="40" class="md:inline mr-2" />
    </div>
  </ng-template>
  <ng-template pTemplate="submenuheader" let-item>
    <div class="flex flex-row gap-2 align-items-center">
      <span [ngClass]="item.icon"></span>
      <span class="font-bold">{{ item.label }}</span>
    </div>
  </ng-template>
  <ng-template pTemplate="item" let-item>
    <a
      pRipple
      class="flex align-items-center p-menuitem-link docs-menuitem pl-4"
      (click)="onClick(item.state.iframeId); mobileToolbar.toggleFold()"
      [routerLink]="['/docs', item.state.iframeId]"
      [routerLinkActive]="['is-active']">
      <div class="flex flex-row gap-2 align-items-center">
        <span [ngClass]="item.icon"></span>
        <span>{{ item.label }}</span>
      </div>
    </a>
  </ng-template>
</p-menu>

<div class="w-full h-screen" *ngIf="iframeUrl">
  <iframe [src]="iframeUrl | sanitize" allowfullscreen width="100%" height="100%" frameborder="0"></iframe>
</div>

<fizjo-pro-bottom-toolbar
  #mobileToolbar
  [items]="[]"
  (stateChange)="changeMenuState($event)"></fizjo-pro-bottom-toolbar>
