import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { FormTarget } from '@fizjo-pro/ui-appointment';
import { helpResolve } from '@kate-fizjo/practice-shared/tools';
import { Store } from '@ngrx/store';
import { switchMap } from 'rxjs';

import { FormTemplateDto } from './api/models/form-template-dto';
import { formTemplateActions } from './state/form-template.actions';
import { selectById, selectReady, selectTemplateFor } from './state/form-template.selectors';
import { selectFormTemplateIdFromAppointment } from '../../../../data-appointment/src/lib/state/appointment.selectors';

export const formTemplatesResolver: ResolveFn<any> = (route, state, store = inject(Store)) =>
  store.select(selectReady).pipe(helpResolve(store, formTemplateActions.read()));

export const singleFormTemplateResolver: ResolveFn<FormTemplateDto | undefined> = (
  route,
  state,
  store = inject(Store)
) => {
  const formTarget: FormTarget = route.data['formTarget'];

  return store.select(selectReady).pipe(
    helpResolve(store, formTemplateActions.read()),
    switchMap(() => store.select(selectTemplateFor(formTarget)))
  );
};

export const resolveTemplateForAppointment: ResolveFn<FormTemplateDto | undefined> = (
  route,
  state,
  store = inject(Store)
) => {
  return store.select(selectReady).pipe(
    helpResolve(store, formTemplateActions.read()),
    switchMap(() => store.select(selectFormTemplateIdFromAppointment(route.params['appointmentId']))),
    switchMap((templateId: string) => store.select(selectById(templateId)))
  );
};
