import { createAction, createActionGroup, props } from '@ngrx/store';

import { EQUIPMENT_FEATURE_KEY, EquipmentFilter } from './equipment.model';
import { EquipmentDto, EquipmentPayloadDto } from '../api/models';

export const equipmentActions = createActionGroup({
  events: {
    delete: props<{ equipmentId: string }>(),
    restore: props<{ equipmentId: string }>(),
    success: props<{ equipment: EquipmentDto }>(),
  },
  source: EQUIPMENT_FEATURE_KEY,
});
export const createEquipment = createAction(
  `[${EQUIPMENT_FEATURE_KEY}] create equipment`,
  props<{ payload: EquipmentPayloadDto }>()
);

export const createEquipmentFromImportedProcedure = createAction(
  `[${EQUIPMENT_FEATURE_KEY}] create equipment from imported procedure`,
  props<{ payload: EquipmentPayloadDto }>()
);

export const createEquipmentSuccess = createAction(
  `[${EQUIPMENT_FEATURE_KEY}] create equipment success`,
  props<{ equipment: EquipmentDto }>()
);

export const createEquipmentFromImportedProcedureSuccess = createAction(
  `[${EQUIPMENT_FEATURE_KEY}] create equipment from imported procedure success`,
  props<{ equipment: EquipmentDto }>()
);

export const fetchEquipmentList = createAction(`[${EQUIPMENT_FEATURE_KEY}] fetch equipment list`);

export const fetchEquipmentListSuccess = createAction(
  `[${EQUIPMENT_FEATURE_KEY}] fetch equipment list success`,
  props<{ equipments: EquipmentDto[] }>()
);

export const updateEquipment = createAction(
  `[${EQUIPMENT_FEATURE_KEY}] update equipment`,
  props<{ equipmentId: string; payload: EquipmentPayloadDto }>()
);

export const updateEquipmentSuccess = createAction(
  `[${EQUIPMENT_FEATURE_KEY}] update equipment success`,
  props<{ equipment: EquipmentDto }>()
);

export const message = createAction(
  `[${EQUIPMENT_FEATURE_KEY}] present message`,
  props<{ message: string; severity: string }>()
);

export const deleteEquipmentSuccess = createAction(`[${EQUIPMENT_FEATURE_KEY}] delete equipment success`);

export const setFilter = createAction(
  `[${EQUIPMENT_FEATURE_KEY}] delete equipment success`,
  props<{ filter: EquipmentFilter }>()
);
