import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

@NgModule({
  imports: [CommonModule, ToastModule],
  providers: [MessageService],
})
export class MessagesModule {
  public static forRoot(): ModuleWithProviders<MessagesModule> {
    return {
      ngModule: MessagesModule,
      providers: [MessageService],
    };
  }
}
