import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { authActions } from '@fizjo-pro/data-auth';
import { Store } from '@ngrx/store';
import jwtDecode from 'jwt-decode';
import { CookieService } from 'ngx-cookie';

const REFRESH_TIME_BEFORE_EXPIRATION = 10 * 60 * 1000;

export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  cookies = inject(CookieService),
  store = inject(Store),
  router = inject(Router)
): boolean => {
  const token: string | undefined = cookies.get('jwt');

  if (!token) {
    store.dispatch(authActions.setUnauthenticated({ source: 'auth-guard' }));
    router.navigate(['/auth']);

    return false;
  }

  const decoded = jwtDecode<{ termsAcceptedDate: Date | null; forcePasswordChange: boolean; exp: number }>(token);

  if (decoded.forcePasswordChange || !decoded.termsAcceptedDate) {
    router.navigate(['/auth/init']);

    return false;
  }
  if (Date.now() + REFRESH_TIME_BEFORE_EXPIRATION > decoded.exp * 1000) {
    store.dispatch(authActions.canActivatePath({ segments: route.url }));
  } else {
    store.dispatch(authActions.setAuthenticated({ tokenDto: { token } }));
  }

  return true;
};
