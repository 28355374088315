import { inject, Injectable, Renderer2 } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ReadTemplateService {
  #fileContent$: Subject<string> = new Subject<string>();
  #renderer: Renderer2 = inject(Renderer2);
  #input!: HTMLInputElement;

  selectFile$(): Observable<string> {
    this.#input = this.#renderer.createElement('input');
    [
      { param: 'position', value: 'absolute' },
      { param: 'top', value: '-5000' },
      { param: 'zIndex', value: '-999' },
      { param: 'overflow', value: 'hidden' },
      { param: 'width', value: '0px' },
    ].forEach(parameter => this.#renderer.setStyle(this.#input, parameter.param, parameter.value));
    this.#renderer.setAttribute(this.#input, 'type', 'file');
    this.#renderer.setAttribute(this.#input, 'accept', '*.template.json');
    this.#renderer.listen(this.#input, 'change', this.#changeHandler.bind(this));
    this.#renderer.appendChild(document.body, this.#input);
    this.#input.click();

    return this.#fileContent$.asObservable();
  }

  #changeHandler(event: Event): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const file: File | undefined = target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (): void => {
        this.#fileContent$.next(reader.result as string);
        this.#fileContent$.complete();
        document.body.removeChild(this.#input);
      };

      reader.readAsText(file);
    }
  }
}
