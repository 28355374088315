import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ProgressBarService } from '@fizjo-pro/shared/ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { map, switchMap, tap } from 'rxjs/operators';

import * as PatientActions from './patient.actions';
import { PatientDto } from '../api/models';
import { PatientService } from '../api/services/patient.service';

@Injectable()
export class PatientEffects {
  #actions$: Actions = inject(Actions);
  #patientService: PatientService = inject(PatientService);
  #messageService: MessageService = inject(MessageService);
  #router: Router = inject(Router);
  #ref!: DynamicDialogRef | undefined;
  #progressBarService: ProgressBarService = inject(ProgressBarService);
  #translateService: TranslateService = inject(TranslateService);

  public loadPatient$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(PatientActions.loadPatients),
      switchMap(() => this.#patientService.patientControllerReadAll()),
      map((patients: PatientDto[]) => PatientActions.loadPatientsSuccess({ patients }))
    );
  });

  public savePatient$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(PatientActions.savePatient),
      switchMap(action =>
        this.#patientService.patientControllerCreate({
          body: action.payload,
        })
      ),
      map((patient: PatientDto) => PatientActions.savePatientSuccess({ patient }))
    );
  });

  public savePatientFromDialog$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(PatientActions.savePatientFromDialog),
      tap(action => {
        this.#ref = action.ref;
      }),
      switchMap(action =>
        this.#patientService.patientControllerCreate({
          body: action.payload,
        })
      ),
      map((patient: PatientDto) => PatientActions.savePatientSuccessAndCloseDialog({ patient })),
      tap(() => {
        this.#ref?.close();
      })
    );
  });

  public savePatientSuccess$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(PatientActions.savePatientSuccess),
      tap(() => this.#progressBarService.hide()),
      switchMap(action => this.#router.navigate(['/patients', action.patient._id])),
      map(() =>
        PatientActions.message({
          message: this.#translateService.instant('patient.saveSuccess.detail'),
          severity: 'success',
        })
      )
    );
  });

  public updatePatient$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(PatientActions.updatePatient),
      switchMap(action =>
        this.#patientService.patientControllerUpdate({
          body: action.payload,
          patientId: action.patientId,
        })
      ),
      map((patient: PatientDto) => PatientActions.savePatientSuccess({ patient }))
    );
  });

  public patchPatientOwner$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(PatientActions.patchPatientOwner),
      switchMap(action =>
        this.#patientService.patientControllerPatchOwner({
          body: { owner: action.owner },
          patientId: action.patientId,
        })
      ),
      map((patient: PatientDto) => PatientActions.patchPatientOwnerSuccess({ patient }))
    );
  });

  public deletePatientOwner$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(PatientActions.deletePatientOwner),
      switchMap(action =>
        this.#patientService.patientControllerDeleteOwner({
          patientId: action.patientId,
        })
      ),
      map((patient: PatientDto) => PatientActions.patchPatientOwnerSuccess({ patient }))
    );
  });

  public patchPatientOwnerSuccess$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(PatientActions.patchPatientOwnerSuccess),
      tap(() => this.#progressBarService.hide()),
      map(() =>
        PatientActions.message({
          message: this.#translateService.instant('patient.changeOwnerSuccess.detail'),
          severity: 'success',
        })
      )
    );
  });

  public showMessage$ = createEffect(
    () => {
      return this.#actions$.pipe(
        ofType(PatientActions.message),
        tap(action => {
          this.#messageService.add({
            detail: action.message,
            severity: action.severity,
            summary: 'Pacjent',
          });
        })
      );
    },
    { dispatch: false }
  );
}
