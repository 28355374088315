import { ResolveFn } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { of } from 'rxjs';

import { docsMenuItems } from './docs-items.pl';

export interface DocsResolverData {
  docsMenuItems: MenuItem[];
}
export const docsResolver: ResolveFn<DocsResolverData> = () => {
  return of({ docsMenuItems });
};
