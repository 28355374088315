import { MenuItem, PrimeIcons } from 'primeng/api';

export const docsMenuItems: MenuItem[] = [
  {
    label: 'docs.sections.calendar',
    icon: PrimeIcons.CALENDAR,
    items: [
      {
        label: 'docs.items.createVisitFromCalendar',
        icon: PrimeIcons.BOOK,
        state: {
          iframeId: 'createVisitFromCalendar',
        },
      },
    ],
  },
  {
    label: 'docs.sections.visits',
    icon: 'fpi fpi-stethoscope',
    items: [
      {
        label: 'docs.items.createVisit',
        icon: PrimeIcons.BOOK,
        state: {
          iframeId: 'createVisit',
        },
      },
    ],
  },
  {
    label: 'docs.sections.patients',
    icon: 'fpi fpi-cat',
    items: [
      {
        label: 'docs.items.createPatient',
        icon: PrimeIcons.BOOK,
        state: {
          iframeId: 'createPatient',
        },
      },
    ],
  },
  {
    label: 'docs.sections.owners',
    icon: PrimeIcons.USER,
    items: [
      {
        label: 'docs.items.createOwner',
        icon: PrimeIcons.BOOK,
        state: {
          iframeId: 'createOwner',
        },
      },
    ],
  },
  {
    label: 'docs.sections.definitionsForms',
    icon: PrimeIcons.TH_LARGE,
    items: [
      {
        label: 'docs.items.createTemplateForAppointment',
        icon: PrimeIcons.BOOK,
        state: {
          iframeId: 'createTemplateForAppointment',
        },
      },
      {
        label: 'docs.items.editTemplateForAppointment',
        icon: PrimeIcons.BOOK,
        state: {
          iframeId: 'editTemplateForAppointment',
        },
      },
      {
        label: 'docs.items.fieldsInFormTemplates',
        icon: PrimeIcons.BOOK,
        state: {
          iframeId: 'fieldsInFormTemplates',
        },
      },
      {
        label: 'docs.items.validateTemplateForAppointment',
        icon: PrimeIcons.BOOK,
        state: {
          iframeId: 'validateTemplateForAppointment',
        },
      },
      {
        label: 'docs.items.changeTemplateFormFieldsOrder',
        icon: PrimeIcons.BOOK,
        state: {
          iframeId: 'changeTemplateFormFieldsOrder',
        },
      },
    ],
  },
  {
    label: 'docs.sections.equipment',
    icon: 'fpi fpi-equipment',
    items: [
      {
        label: 'docs.items.equipment.createEquipment',
        icon: PrimeIcons.BOOK,
        state: {
          iframeId: 'createEquipment',
        },
      },
      {
        label: 'docs.items.equipment.editEquipment',
        icon: PrimeIcons.BOOK,
        state: {
          iframeId: 'editEquipment',
        },
      },
    ],
  },
  {
    label: 'docs.sections.accountSettings',
    icon: PrimeIcons.COG,
    items: [
      {
        label: 'docs.items.changeCompanyData',
        icon: PrimeIcons.BOOK,
        state: {
          iframeId: 'changeCompanyData',
        },
      },
    ],
  },
  {
    label: 'docs.sections.problemSolving',
    icon: PrimeIcons.QUESTION_CIRCLE,
    items: [
      {
        label: 'docs.items.problemSolving.missingFormTemplate',
        icon: PrimeIcons.BOOK,
        state: {
          iframeId: 'missingFormTemplate',
        },
      },
    ],
  },
];
