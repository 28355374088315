export const urls: Record<string, string> = {
  createVisit:
    'https://scribehow.com/embed/Tworzenie_Wizyty_ogolnej_lub_Konsultacji_w_fizjopro__cEM0LBPSTliNpxQY8Z4wjA?',
  editVisitTemplate:
    'https://scribehow.com/embed/Edycja_szablonu_formularza_wizyty_ogolnej_lub_konsultacji__8-aRzL0vQiC6yZFjKTbq8Q?',
  createTemplateForAppointment:
    'https://scribehow.com/embed/Tworzenie_szablonu_formularza_wizyty_ogolnej_lub_konsultacji__iHi-1cgjTXunvYUlYKqiKw?',
  editTemplateForAppointment:
    'https://scribehow.com/embed/Edycja_szablonu_formularza_wizyty_ogolnej_lub_konsultacji__8-aRzL0vQiC6yZFjKTbq8Q?',
  validateTemplateForAppointment:
    'https://scribehow.com/embed/Walidacja_formularza_opisu_wizyty__fNW3nXcyR_GiS_J8fvGpJw?',
  createVisitFromCalendar:
    'https://scribehow.com/embed/Tworzenie_nowej_wizyty_i_integracja_z_kalendarzem__4VjaqCgkRTGhXxRa5Dwdjg',
  createPatient:
    'https://scribehow.com/embed/Tworzenie_nowego_pacjenta_i_przypisywanie_go_do_istniejacego_juz_wlasciciela__7QgNUNN9RTaNhE75SN1STg',
  changeCompanyData:
    'https://scribehow.com/embed/Zmiana_danych_firmowych_logo_oraz_adresu_mail_w_aplikacji_fizjopro__-6TbtRQQR5Kqn_dTINDLWQ',
  createOwner:
    'https://scribehow.com/embed/Tworzenie_wlasciciela_i_przypisywanie_jego_zwierzecia__zGY7FtoCR26ANqdfVv4nfQ',
  fieldsInFormTemplates: 'https://scribehow.com/shared/Rozne_pola_w_szablonie_formularzy__M6AvN1R0SF-FLdDzQiWhcw',
  changeTemplateFormFieldsOrder:
    'https://scribehow.com/embed/Zmiana_kolejnosci_pol_szablonu_formularza__e10j_i0ZTGSKUtRrEAFy0w',
  missingFormTemplate: 'https://scribehow.com/shared/Brak_szablonu_formularza_do_wizyty__DihFRNqiT0O18A3CqZqFrw',
  createEquipment: 'https://scribehow.com/shared/Dodawanie_wyposazenia_w_fizjopro__r7k2auygQCKs8MtMKuRIZQ',
  editEquipment: 'https://scribehow.com/shared/Edycja_wyposazenia_w_fizjopro__ihBVFoWYQVKt9-rtKQr1zA',
};
