import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { DateTime } from 'luxon';

export interface TimeRangeForm {
  date: FormControl<Date>;
  from: FormControl<Date>;
  to: FormControl<Date>;
}

export interface TimeRangeFormValue {
  date: Date;
  from: Date;
  to: Date;
}

export interface DateRange {
  from: Date;
  to: Date;
}
export function rangeValidator(control: AbstractControl): ValidationErrors | null {
  const { from, to }: DateRange = control.getRawValue();

  if (DateTime.fromJSDate(from) >= DateTime.fromJSDate(to)) {
    return {
      incorrectDateRange: true,
    };
  }

  return null;
}

export function roundMinutes(date: Date, locale = 'pl'): DateTime {
  const dateTime = DateTime.fromJSDate(date).setLocale(locale);
  const roundedMinutes = Math.floor(dateTime.minute / 10) * 10;

  return dateTime.set({
    minute: roundedMinutes,
    second: 0,
    millisecond: 0,
  });
}

export function timeRangeForm(locale: string): FormGroup<TimeRangeForm> {
  const dt: DateTime = roundMinutes(new Date(), locale);

  return new FormGroup<TimeRangeForm>(
    {
      date: new FormControl<Date>(dt.startOf('day').toJSDate(), { nonNullable: true }),
      from: new FormControl<Date>(dt.startOf('minute').toJSDate(), { nonNullable: true }),
      to: new FormControl<Date>(dt.startOf('minute').plus({ hour: 1, minute: 30 }).toJSDate(), { nonNullable: true }),
    },
    { validators: [rangeValidator] }
  );
}
