import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { switchMap } from 'rxjs';
import { from } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import * as EquipmentAction from './equipment.actions';
import { equipmentActions } from './equipment.actions';
import { EquipmentDto } from '../api/models';
import { EquipmentService } from '../api/services/equipment.service';

@Injectable()
export class EquipmentEffects {
  #action$: Actions = inject(Actions);
  #equipmentService: EquipmentService = inject(EquipmentService);
  #router: Router = inject(Router);
  #messageService: MessageService = inject(MessageService);
  #translateService: TranslateService = inject(TranslateService);

  public createEquipment$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(EquipmentAction.createEquipment),
      switchMap(action =>
        this.#equipmentService.equipmentControllerCreate({
          body: action.payload,
        })
      ),
      map((equipment: EquipmentDto) => EquipmentAction.createEquipmentSuccess({ equipment }))
    );
  });
  public createEquipmentFromImportedProcedure$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(EquipmentAction.createEquipmentFromImportedProcedure),
      switchMap(action =>
        this.#equipmentService.equipmentControllerCreate({
          body: action.payload,
        })
      ),
      map((equipment: EquipmentDto) => EquipmentAction.createEquipmentFromImportedProcedureSuccess({ equipment }))
    );
  });

  public createEquipmentFromImportedProcedureSuccess$ = createEffect(
    () => {
      return this.#action$.pipe(
        ofType(EquipmentAction.createEquipmentFromImportedProcedureSuccess),
        map(action => {
          this.#messageService.add({
            detail: this.#translateService.instant('equipment.createSuccess.detail', {
              name: action.equipment.name,
            }),
            severity: 'success',
            summary: this.#translateService.instant('equipment.importSuccess.summary'),
          });
        })
      );
    },
    { dispatch: false }
  );

  public createEquipmentSuccess$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(EquipmentAction.createEquipmentSuccess),
      map(action =>
        EquipmentAction.message({
          message: this.#translateService.instant('equipment.createSuccess.detail', {
            name: action.equipment.name,
          }),
          severity: 'success',
        })
      )
    );
  });
  public fetchEquipmentList$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(EquipmentAction.fetchEquipmentList),
      switchMap(() => this.#equipmentService.equipmentControllerReadAll()),
      map((equipments: EquipmentDto[]) => EquipmentAction.fetchEquipmentListSuccess({ equipments }))
    );
  });
  public updateEquipment$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(EquipmentAction.updateEquipment),
      switchMap(action =>
        this.#equipmentService.equipmentControllerUpdate({
          body: action.payload,
          equipmentId: action.equipmentId,
        })
      ),
      map((equipment: EquipmentDto) => EquipmentAction.updateEquipmentSuccess({ equipment }))
    );
  });

  public updateEquipmentSuccess$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(EquipmentAction.updateEquipmentSuccess),
      map(action =>
        EquipmentAction.message({
          message: this.#translateService.instant('equipment.updateSuccess.detail', { name: action.equipment.name }),
          severity: 'success',
        })
      )
    );
  });
  public deleteEquipment$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(equipmentActions.delete),
      switchMap(action => this.#equipmentService.equipmentControllerDelete({ equipmentId: action.equipmentId })),
      map((equipment: EquipmentDto) => equipmentActions.success({ equipment }))
    );
  });
  public restoreEquipment$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(equipmentActions.restore),
      switchMap(action =>
        this.#equipmentService.equipmentControllerPatchEquipmentState({
          body: { active: true },
          equipmentId: action.equipmentId,
        })
      ),
      map((equipment: EquipmentDto) => equipmentActions.success({ equipment }))
    );
  });
  public showMessage$ = createEffect(
    () => {
      return this.#action$.pipe(
        ofType(EquipmentAction.message),
        tap(action => {
          this.#messageService.add({
            detail: action.message,
            severity: action.severity,
            summary: this.#translateService.instant('patient.patient'),
          });
        }),
        map(() => from(this.#router.navigate(['/settings', 'equipment'])))
      );
    },
    { dispatch: false }
  );
}
