import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  inject,
  LOCALE_ID,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DateTime, Duration } from 'luxon';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { FloatLabelModule } from 'primeng/floatlabel';
import { map, Observable } from 'rxjs';

import { DateRange, timeRangeForm, TimeRangeForm, TimeRangeFormValue } from './time-range.form';

@Component({
  selector: 'fizjo-pro-time-range',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    CalendarModule,
    SharedModule,
    TranslateModule,
    FloatLabelModule,
    ReactiveFormsModule,
  ],
  templateUrl: './time-range.component.html',
  styleUrl: './time-range.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeRangeComponent),
      multi: true,
    },
  ],
})
export class TimeRangeComponent implements ControlValueAccessor, OnInit {
  #changeCallback!: (range: DateRange) => void;
  #locale: string = inject(LOCALE_ID);
  @Output() hide: EventEmitter<void> = new EventEmitter<void>();
  duration$!: Observable<string>;
  timeRangeForm: FormGroup<TimeRangeForm> = timeRangeForm(this.#locale);

  ngOnInit() {
    this.duration$ = this.timeRangeForm.valueChanges.pipe(
      map(range => {
        if (range.to && range.from) {
          const duration: Duration = DateTime.fromJSDate(range.to)
            .setLocale(this.#locale)
            .diff(DateTime.fromJSDate(range.from));

          return duration
            .rescale()
            .set({ milliseconds: 0, second: 0 })
            .normalize()
            .shiftTo('hours', 'minutes')
            .toHuman({
              listStyle: 'short',
              compactDisplay: 'short',
            });
        }

        return '';
      })
    );
  }

  registerOnChange(callback: (range: DateRange) => void): void {
    this.#changeCallback = callback;
  }

  registerOnTouched(callback: () => void): void {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.timeRangeForm.valueChanges.subscribe(() => callback());
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      ['date', 'start', 'end'].forEach(key => this.timeRangeForm.get(key)?.disable());
    } else {
      ['date', 'start', 'end'].forEach(key => this.timeRangeForm.get(key)?.enable());
    }
  }

  writeValue(dateRange: DateRange | null): void {
    if (dateRange) {
      this.timeRangeForm.setValue(
        {
          date: DateTime.fromJSDate(dateRange.from).toJSDate(),
          from: DateTime.fromJSDate(dateRange.from).startOf('minute').toJSDate(),
          to: DateTime.fromJSDate(dateRange.to).startOf('minute').toJSDate(),
        },
        { emitEvent: false }
      );
    }
  }
  emitValue(): void {
    if (this.timeRangeForm.valid) {
      const dateRange = this.timeRangeForm.getRawValue();

      this.hide.emit();
      this.#changeCallback(this.convertFormValue(dateRange));
    }
  }

  convertFormValue(value: TimeRangeFormValue): DateRange {
    return {
      from: DateTime.fromJSDate(value.date)
        .set({ hour: value.from.getHours(), minute: value.from.getMinutes() })
        .toJSDate(),

      to: DateTime.fromJSDate(value.date)
        .set({ hour: value.from.getHours(), minute: value.from.getMinutes() })
        .toJSDate(),
    };
  }
}
