/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { FormTarget } from '@fizjo-pro/ui-appointment';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FORM_TEMPLATE_FEATURE_KEY, FormTemplateFilter, FormTemplateState } from './form-template.model';
import { selectAll } from './form-template.reducer';
import { FormTemplateDto } from '../api/models/form-template-dto';

export const selectTemplateState = createFeatureSelector<FormTemplateState>(FORM_TEMPLATE_FEATURE_KEY);

export const selectReady = createSelector(selectTemplateState, (state: FormTemplateState) => state.ready);

export const selectTemplates = createSelector(selectTemplateState, (state: FormTemplateState) =>
  selectAll(state).filter((template: FormTemplateDto) => {
    switch (state.filter) {
      case FormTemplateFilter.ACTIVE:
        return template.active;
      case FormTemplateFilter.INACTIVE:
        return !template.active;
      default:
        return true;
    }
  })
);

export const selectActiveOnly = createSelector(selectTemplateState, (state: FormTemplateState) =>
  selectAll(state).filter((eq: FormTemplateDto) => eq.active)
);

export const selectById = (equipmentId: string) =>
  createSelector(selectTemplateState, (state: FormTemplateState) => state.entities[equipmentId]);

export const selectFilterValue = createSelector(selectTemplateState, (state: FormTemplateState) => state.filter);

export const selectTemplateFor = (selectFor: FormTarget) =>
  createSelector(selectActiveOnly, (templates: FormTemplateDto[]) =>
    templates.find((template: FormTemplateDto) => template.selectedFor === selectFor)
  );
