import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateTime } from 'luxon';
export interface DateTimeForm {
  date: FormControl<Date>;
  time: FormControl<Date>;
}

export interface DateTimeFormValue {
  date: Date;
  time: Date;
}

export function mergeDates(dateTimeFormValue: DateTimeFormValue, locale = 'pl'): Date {
  const date: DateTime = DateTime.fromJSDate(dateTimeFormValue.date);
  const time: DateTime = DateTime.fromJSDate(dateTimeFormValue.time);

  return DateTime.fromObject({
    year: date.year,
    month: date.month,
    day: date.day,
    hour: time.hour,
    minute: time.minute,
    second: 0,
  })
    .setLocale(locale)
    .toJSDate();
}

function maxDateValidator(maxDate?: Date): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const date: Date = mergeDates(control.getRawValue());

    if (maxDate && date > maxDate) {
      return { maxDate: true };
    }

    return null;
  };
}

export function dateTimeForm(maxDate?: Date, locale = 'pl'): FormGroup<DateTimeForm> {
  const dt: DateTime = DateTime.now().setLocale(locale);

  return new FormGroup<DateTimeForm>(
    {
      date: new FormControl<Date>(dt.toJSDate(), { nonNullable: true }),
      time: new FormControl<Date>(dt.toJSDate(), { nonNullable: true }),
    },
    { validators: [maxDateValidator(maxDate)] }
  );
}
