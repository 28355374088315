import { Injectable } from '@angular/core';

import { urls } from './pl';

export type DocId = keyof typeof urls;

@Injectable()
export class DocsService {
  getUrl(iframeId: DocId): string | undefined {
    return urls[iframeId];
  }
}
