import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { valueAccessorFactory } from '@kate-fizjo/practice-shared/tools';
import { TranslateModule } from '@ngx-translate/core';
import { AutoFocusModule } from 'primeng/autofocus';
import { ButtonModule } from 'primeng/button';
import { ChipsModule } from 'primeng/chips';
import { KeyFilterModule } from 'primeng/keyfilter';
import { TooltipModule } from 'primeng/tooltip';
import { map, Observable } from 'rxjs';

export interface PinCodeForm {
  pinCodeArray: FormArray<FormControl<number | null | '*'>>;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ChipsModule,
    KeyFilterModule,
    AutoFocusModule,
    ButtonModule,
    TooltipModule,
    TranslateModule,
  ],
  providers: [valueAccessorFactory(() => PinCodeComponent)],
  selector: 'fizjo-pro-pin-code',
  standalone: true,
  styleUrls: ['./pin-code.component.scss'],
  templateUrl: './pin-code.component.html',
})
export class PinCodeComponent implements OnInit, ControlValueAccessor {
  #pinCode$!: Observable<string>;
  #destroyRef: DestroyRef = inject(DestroyRef);
  @Input() public pinCount = 4;
  @Input() public label!: string;
  @Output() public accept: EventEmitter<string> = new EventEmitter<string>();
  public focusedIndex = 0;
  public pinCodeGroup: FormGroup<PinCodeForm> = new FormGroup({
    pinCodeArray: new FormArray<FormControl<number | null | '*'>>([]),
  });

  @ViewChildren('input') public inputsList!: QueryList<ElementRef>;

  public ngOnInit(): void {
    for (let i = 0; i < this.pinCount; i++) {
      this.pinCodeGroup.controls.pinCodeArray.push(new FormControl<number | null>(null));
    }

    this.#pinCode$ = this.pinCodeGroup.controls.pinCodeArray.valueChanges.pipe(map(this.rawValueToPinCode.bind(this)));
  }

  public keyUpHandler(event: KeyboardEvent, index: number): void {
    const isDigit: boolean = /Digit/i.test(event.code);

    if ((isDigit || event.code === 'ArrowRight') && index < this.pinCount - 1) {
      this.inputItem(index + 1).select();
    }

    if ((event.code === 'Backspace' || event.code === 'ArrowLeft') && index > 0) {
      this.inputItem(index - 1).select();
    }

    if (event.code === 'Enter') {
      const pinCode: string = this.rawValueToPinCode(this.pinCodeGroup.controls.pinCodeArray.getRawValue());

      if (pinCode.length === this.pinCount) {
        this.accept.emit(pinCode);
      }
    }
  }

  public registerOnChange(callback: (pin: string) => void): void {
    this.#pinCode$.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(callback);
  }

  public registerOnTouched(callback: (pin: string) => void): void {
    this.#pinCode$.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(callback);
  }

  public writeValue(pinCode: string): void {
    const pattern = new RegExp(`^[0-9]{${this.pinCount}}$`);

    if (pinCode && !pattern.test(pinCode)) {
      for (let i = 0; i < this.pinCount; i++) {
        this.pinCodeGroup.controls.pinCodeArray.at(i).setValue('*');
      }
    }
    // The PIN code will be not stored as plain text, so can not be restored
  }

  private inputItem(index: number): HTMLInputElement {
    return Array.from(this.inputsList)[index].nativeElement;
  }

  private rawValueToPinCode(rawPinCode: (number | null | '*')[]): string {
    return rawPinCode.filter((pinNumber: number | null | '*') => pinNumber !== null && pinNumber !== '*').join('');
  }

  public reset(): void {
    for (let i = 0; i < this.pinCount; i++) {
      this.pinCodeGroup.controls.pinCodeArray.at(i).reset(null);
    }
  }
}
