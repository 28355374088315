import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { DocsService } from '@fizjo-pro/data-doc';
import { BottomToolbarComponent, SanitizePipe } from '@fizjo-pro/shared/ui';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RxLet } from '@rx-angular/template/let';
import { MenuItem, MessageService } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MenuModule } from 'primeng/menu';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { StyleClassModule } from 'primeng/styleclass';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';

import { docsMenuItems } from '../docs-items.pl';

const SCROLLABLE_QUERY = '?removeLogo=true&as=scrollable';

@Component({
  imports: [
    ReactiveFormsModule,
    CommonModule,
    DataViewModule,
    CardModule,
    ButtonModule,
    ChipModule,
    DynamicDialogModule,
    FieldsetModule,
    PanelModule,
    CarouselModule,
    InputSwitchModule,
    RxLet,
    SelectButtonModule,
    BadgeModule,
    TranslateModule,
    DialogModule,
    TableModule,
    CheckboxModule,
    TabViewModule,
    RouterLink,
    TooltipModule,
    NgOptimizedImage,
    ScrollPanelModule,
    StyleClassModule,
    RippleModule,
    MenuModule,
    SanitizePipe,
    SidebarModule,
    BottomToolbarComponent,
    PanelMenuModule,
  ],
  providers: [DialogService, MessageService, DocsService],
  selector: 'fizjo-pro-docs-page',
  standalone: true,
  styleUrls: ['./docs-page.component.scss'],
  templateUrl: './docs-page.component.html',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DocsPageComponent implements OnInit {
  #docsService: DocsService = inject(DocsService);
  #translateService: TranslateService = inject(TranslateService);

  iframeUrl = '';
  isMobileMenuFolded = false;

  menuItems: MenuItem[] = docsMenuItems;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private destroyRef: DestroyRef
  ) {}

  public ngOnInit(): void {
    this.route.data.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(data => {
      this.menuItems = this.#translate(data['docsData'].docsMenuItems);

      const id = this.route.snapshot.params['id'];

      if (this.#docsService.getUrl(id)) {
        this.iframeUrl = `${this.#docsService.getUrl(id)}${SCROLLABLE_QUERY}`;
      } else {
        const firstMenuItem = this.menuItems[0]?.items?.[0];
        const iframeId: string | undefined = firstMenuItem?.state?.['iframeId'];

        if (iframeId) {
          this.router.navigate([`/docs/${iframeId}`]);
        } else {
          this.router.navigate(['/']);
        }
      }
    });
  }

  onClick(iframeId: string): void {
    this.iframeUrl = `${this.#docsService.getUrl(iframeId)}${SCROLLABLE_QUERY}`;
  }

  changeMenuState(state: 'fold' | 'unfold'): void {
    this.isMobileMenuFolded = state === 'unfold';
  }

  #translate(menuItems: MenuItem[]): MenuItem[] {
    return menuItems.map(item => ({
      ...item,
      label: item.label ? this.#translateService.instant(item.label) : undefined,
      items: item.items ? this.#translate(item.items) : undefined,
    }));
  }
}
