import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { authActions } from '@fizjo-pro/data-auth';
import { InternalServerErrorDialogComponent, ProgressBarService } from '@fizjo-pro/shared/ui';
import { OutOfResourcesDialogComponent } from '@fizjo-pro/ui-account';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { catchError, NEVER, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptorHandler {
  #messageService: MessageService = inject(MessageService);
  #translateService: TranslateService = inject(TranslateService);
  #progressBarService: ProgressBarService = inject(ProgressBarService);
  #router: Router = inject(Router);
  #store: Store = inject(Store);
  #dialogService: DialogService = inject(DialogService);

  handleByToast(status: number, detail?: string, summary?: string): void {
    this.#progressBarService.hide();
    this.#messageService.add({
      detail: detail || this.#translateService.instant(`errors.${status}.detail`),
      summary: summary || this.#translateService.instant(`errors.${status}.summary`),
      icon: 'pi pi-exclamation-triangle',
      severity: 'error',
      sticky: true,
      closable: true,
    });
  }

  async handleUnauthorized(): Promise<void> {
    this.#progressBarService.hide();
    this.#store.dispatch(authActions.setUnauthenticatedAtInterceptor({ source: 'interceptor' }));
    await this.#router.navigate(['/auth']);
  }

  showOutOfResourcesDialog(error: HttpErrorResponse): void {
    this.#progressBarService.hide();
    this.#dialogService.open(OutOfResourcesDialogComponent, {
      width: '600px',
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw',
      },
      data: {
        resource: error.error.resource,
      },
    });
  }

  showInternalServerErrorDialog(): void {
    this.#progressBarService.hide();
    this.#dialogService.open(InternalServerErrorDialogComponent, {
      width: '600px',
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw',
      },
    });
  }

  handleDefault(): void {
    this.#progressBarService.hide();
    this.#messageService.add({
      detail: this.#translateService.instant(`errors.default.detail`),
      summary: this.#translateService.instant(`errors.default.summary`),
      icon: 'pi pi-exclamation-triangle',
      severity: 'error',
      sticky: true,
      closable: true,
    });
  }
}
export const HandleErrorPassThrough: HttpContextToken<number[]> = new HttpContextToken<number[]>(() => []);
export const httpErrorInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
  handler: HttpErrorInterceptorHandler = inject(HttpErrorInterceptorHandler)
): Observable<HttpEvent<unknown>> => {
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      const statuses: number[] = req.context.get(HandleErrorPassThrough);

      if (statuses.length > 0 && statuses.includes(error.status)) {
        return throwError(() => error);
      }
      switch (error.status) {
        case 401:
        case 403:
          handler.handleUnauthorized();

          return NEVER;
        case 402:
          handler.showOutOfResourcesDialog(error);

          return throwError(() => error);
        case 400:
        case 404:
          handler.handleByToast(error.status);

          return NEVER;
        case 405:
          handler.handleByToast(error.status, error.error.message);

          return NEVER;
        case 500:
        case 501:
        case 502:
          handler.showInternalServerErrorDialog();

          return NEVER;
        default:
          handler.handleDefault();

          return throwError(() => error);
      }
    })
  );
};
