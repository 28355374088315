import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  DestroyRef,
  HostListener,
  inject,
  OnInit,
  Signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { ProductCode } from '@fizjo-pro/data-account';
import { AuthFacade, ScreenLockService, ScreenLockType } from '@fizjo-pro/data-auth';
import { MessagesService } from '@fizjo-pro/data-messages';
import { ScreenLockComponent } from '@fizjo-pro/feat-auth';
import { BlockUiComponent, ProgressBarService } from '@fizjo-pro/shared/ui';
import { AppUserAvatarComponent, SwitchAppUserComponent } from '@fizjo-pro/ui-app-user';
import { ProgressBarComponent, UserViewComponent } from '@fizjo-pro/ui-auth';
import { MessagesNotificationComponent } from '@fizjo-pro/ui-messages';
import { HasLicenseDirective } from '@fizjo-pro/util-licenses';
import { CookiesInfoComponent } from '@gui';
import { AppConfigFacade } from '@kate-fizjo/practice-shared/app-config';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RxIf } from '@rx-angular/template/if';
import { RxLet } from '@rx-angular/template/let';
import { defineElement } from 'lord-icon-element';
import lottie from 'lottie-web';
import { CookieService } from 'ngx-cookie';
import { ConfirmationService, OverlayOptions, PrimeNGConfig } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { MenubarModule } from 'primeng/menubar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ToastModule } from 'primeng/toast';
import { Observable, startWith, take } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppNewVersionService } from './app-new-version.service';
import { RouteService } from './route.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DynamicDialogModule,
    CommonModule,
    RouterOutlet,
    MenubarModule,
    ButtonModule,
    ToastModule,
    BlockUIModule,
    UserViewComponent,
    OverlayPanelModule,
    ProgressSpinnerModule,
    TranslateModule,
    BadgeModule,
    RxIf,
    DropdownModule,
    AvatarModule,
    AppUserAvatarComponent,
    SwitchAppUserComponent,
    MessagesNotificationComponent,
    BlockUiComponent,
    ScreenLockComponent,
    ProgressBarComponent,
    ScrollPanelModule,
    RxLet,
    ProgressBarModule,
    HasLicenseDirective,
    ConfirmDialogModule,
  ],
  providers: [DialogService, AppNewVersionService, ConfirmationService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'fizjo-pro-root',
  standalone: true,
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  #translateService: TranslateService = inject(TranslateService);
  #screenLockService: ScreenLockService = inject(ScreenLockService);
  #destroyRef: DestroyRef = inject(DestroyRef);
  #progressBarService: ProgressBarService = inject(ProgressBarService);
  #appNewVersionService: AppNewVersionService = inject(AppNewVersionService);
  #routeService: RouteService = inject(RouteService);

  ProductCode = ProductCode;

  public isProgressVisible: Signal<boolean> = this.#progressBarService.isProgressVisible;

  public messagesCount$: Observable<number>;
  public outletStyle$!: Observable<Record<string, string>>;
  public timeoutVisible$!: Observable<boolean>;

  public options: OverlayOptions = {
    responsive: {
      direction: 'right-start',
    },
  };

  public sessionTimout$: Observable<number> = inject(AuthFacade).sessionTimeout$;

  constructor(
    private cookies: CookieService,
    private config: PrimeNGConfig,
    private appConfigFacade: AppConfigFacade,
    private messagesService: MessagesService,
    private dialogService: DialogService
  ) {
    this.messagesCount$ = this.messagesService.messagesCount$;
  }

  public ngOnInit(): void {
    this.#appNewVersionService.checkForUpdate();
    this.outletStyle$ = this.#routeService.outletStyle$.pipe(
      startWith({
        height: `${window.innerHeight}px`,
      })
    );
    defineElement(lottie.loadAnimation);
    this.#translateService.setDefaultLang('pl');
    this.#translateService
      .get('primeng')
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(res => this.config.setTranslation(res));
    this.appConfigFacade.init();

    if (!this.cookies.hasKey('fizjoPro:acceptCookies')) {
      this.#openCookiesInfoDialog();
    }

    this.#screenLockService.checkToken();

    this.timeoutVisible$ = this.sessionTimout$.pipe(map((timeout: number) => timeout <= 5 && timeout > 0));
  }

  @HostListener('window:keydown', ['$event'])
  public onKeyUp(event: KeyboardEvent): void {
    if (event.ctrlKey && event.code === 'KeyL') {
      this.#screenLockService.lock(ScreenLockType.LOCK);
    }
  }

  #openCookiesInfoDialog(): void {
    this.#translateService
      .get('appComponent.cookiesInfo')
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe((header: string) =>
        this.dialogService.open(CookiesInfoComponent, {
          data: {},
          header,
          width: '300px',
        })
      );
  }
}
