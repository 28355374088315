import { UrlSegment } from '@angular/router';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { AUTH_FEATURE_KEY, ScreenLockType } from './auth.model';
import { TokenDto } from '../api/models/token-dto';
import { UserDto } from '../api/models/user-dto';
import { UserPayloadDto } from '../api/models/user-payload-dto';

export const authActions = createActionGroup({
  events: {
    'clear state': emptyProps(),
    'fetch own user': emptyProps(),
    'fetch own user success': props<{ user: UserDto }>(),
    'can activate path': props<{ segments: UrlSegment[] }>(),
    lockScreen: props<{ lockType: ScreenLockType }>(),
    'set authenticated': props<{ tokenDto: TokenDto | null }>(),
    'set session timeout': props<{ sessionTimeout: number }>(),
    'set unauthenticated': props<{ uid?: string; source: string }>(),
    unlockScreen: emptyProps(),
    updateUserData: props<{ uid: string; payload: UserPayloadDto }>(),
    updateUserDataSuccess: props<{ user: UserDto }>(),
  },
  source: AUTH_FEATURE_KEY,
});
