<div class="flex flex-column gap-2" [formGroup]="timeRangeForm">
  <p-calendar [inline]="true" [formControl]="timeRangeForm.controls.date" dateFormat="dd-mm-yy"></p-calendar>
  <div class="flex flex-row p-2 gap-2">
    <p-floatLabel>
      <p-calendar
        timeSeparator=":"
        [stepMinute]="10"
        [timeOnly]="true"
        class="w-6"
        [formControl]="timeRangeForm.controls.from"></p-calendar>
      <label translate>appointment.startTime</label>
    </p-floatLabel>
    <p-floatLabel>
      <p-calendar
        timeSeparator=":"
        [stepMinute]="10"
        [formControl]="timeRangeForm.controls.to"
        [timeOnly]="true"
        class="w-6"></p-calendar>
      <label translate>appointment.endTime</label>
    </p-floatLabel>
  </div>
  <div class="h-1rem flex flex-column justify-content-center px-2">
    @if (timeRangeForm.hasError('incorrectDateRange')) {
      <small translate class="p-error">errors.incorrectDateRange</small>
    } @else {
      <div class="text-sm">
        {{ timeRangeForm.controls.date.value | date: 'd MMM yyyy' }}:&nbsp;
        {{ timeRangeForm.controls.from.value | date: 'hh:mm' }}-{{
          timeRangeForm.controls.to.value | date: 'hh:mm'
        }}&nbsp;
        <ng-container *ngIf="duration$ | async as duration"> ({{ duration }}) </ng-container>
      </div>
    }
  </div>
  <div class="p-2">
    <p-button
      (click)="emitValue()"
      [label]="'accept' | translate"
      styleClass="w-full"
      [disabled]="timeRangeForm.pristine || timeRangeForm.invalid"></p-button>
  </div>
</div>
