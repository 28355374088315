import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ProgressBarService } from '@fizjo-pro/shared/ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';
import { Observable, switchMap } from 'rxjs';
import { from } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import * as TemplateActions from './form-template.actions';
import { formTemplateActions } from './form-template.actions';
import { FormTemplateDto } from '../api/models';
import { FormTemplateService } from '../api/services/form-template.service';

@Injectable()
export class FormTemplateEffects {
  #action$: Actions = inject(Actions);
  #formTemplateService: FormTemplateService = inject(FormTemplateService);
  #router: Router = inject(Router);
  #messageService: MessageService = inject(MessageService);
  #progressBarService: ProgressBarService = inject(ProgressBarService);

  public create$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(formTemplateActions.create),
      switchMap(action =>
        this.#formTemplateService.formTemplateControllerCreate({
          body: action.payload,
        })
      ),
      map((formTemplate: FormTemplateDto) => formTemplateActions.createSuccess({ formTemplate }))
    );
  });
  public createSuccess$ = createEffect(
    () => {
      return this.#action$.pipe(
        ofType(formTemplateActions.createSuccess),
        tap(() => {
          this.#router.navigate(['/settings', 'form-template']);
        }),
        map(action =>
          TemplateActions.message({
            message: `Zapisano nowy wzór formularza ${action.formTemplate.name}`,
            severity: 'success',
          })
        )
      );
    },
    { dispatch: false }
  );
  public fetchList$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(formTemplateActions.read),
      switchMap(() => this.#formTemplateService.formTemplateControllerReadAll()),
      map((templates: FormTemplateDto[]) => formTemplateActions.readSuccess({ templates }))
    );
  });
  public update$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(TemplateActions.update),
      switchMap(action =>
        this.#formTemplateService.formTemplateControllerUpdate({
          body: action.payload,
          templateId: action.templateId,
        })
      ),
      map((formTemplate: FormTemplateDto) => TemplateActions.updateSuccess({ formTemplate }))
    );
  });
  public updateSuccess$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(TemplateActions.updateSuccess),
      map(action =>
        TemplateActions.message({
          message: `Zaktualizowano wzór formularza ${action.formTemplate.name}`,
          severity: 'success',
        })
      )
    );
  });
  public hide$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(TemplateActions.hideTemplate),
      switchMap(action =>
        this.#formTemplateService.formTemplateControllerSetActive({
          body: { active: false },
          templateId: action.templateId,
        })
      ),
      map((template: FormTemplateDto) => TemplateActions.hideTemplateSuccess({ template }))
    );
  });
  public restore$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(TemplateActions.restoreTemplate),
      switchMap(action =>
        this.#formTemplateService.formTemplateControllerSetActive({
          body: { active: true },
          templateId: action.templateId,
        })
      ),
      map((template: FormTemplateDto) => TemplateActions.restoreTemplateSuccess({ template }))
    );
  });
  public delete$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(formTemplateActions.delete),
      switchMap(action => this.#delete$(action.templateId)),
      map((templateId: string) => formTemplateActions.deleteSuccess({ templateId }))
    );
  });
  public deleteSuccess$ = createEffect(
    () => {
      return this.#action$.pipe(
        ofType(formTemplateActions.deleteSuccess),
        tap(() => {
          this.#messageService.add({ detail: 'Usunięto szablon', severity: 'success', summary: 'Wzór formularza' });
        })
      );
    },
    { dispatch: false }
  );
  public showMessage$ = createEffect(
    () => {
      return this.#action$.pipe(
        ofType(TemplateActions.message),
        tap(action => {
          this.#messageService.add({ detail: action.message, severity: action.severity, summary: 'Wzór formularza' });
        }),
        map(() => from(this.#router.navigate(['/settings', 'form-template'])))
      );
    },
    { dispatch: false }
  );
  public setSelection$ = createEffect(() => {
    return this.#action$.pipe(
      ofType(TemplateActions.setSelection),
      switchMap(action =>
        this.#formTemplateService.formTemplateControllerSetSelectedFor({
          body: { selectedFor: action.selectedFor },
          templateId: action.templateId,
        })
      ),
      map((templates: FormTemplateDto[]) => TemplateActions.setSelectionSuccess({ templates }))
    );
  });

  public setSelectionSuccess$ = createEffect(
    () => {
      return this.#action$.pipe(
        ofType(TemplateActions.setSelectionSuccess),
        tap(() => this.#progressBarService.hide())
      );
    },
    { dispatch: false }
  );

  #delete$(templateId: string): Observable<string> {
    return this.#formTemplateService
      .formTemplateControllerDelete({
        templateId,
      })
      .pipe(map(() => templateId));
  }
}
