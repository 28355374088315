<div class="fixed bottom-0 block md:hidden" [style]="{ zIndex: 99999 }" [ngClass]="isFolded() ? 'unfolded' : 'folded'">
  <div
    class="flex flex-row justify-content-between gap-2"
    [ngClass]="isFolded() ? 'w-auto bg-white shadow-4' : 'w-full'">
    <div class="flex flex-row justify-content-start gap-2">
      <p-button
        icon="pi pi-home"
        [rounded]="true"
        [text]="true"
        size="small"
        severity="info"
        routerLink="/"
        *ngIf="isFolded()"></p-button>
      @for (menuItem of items; track menuItem) {
        <p-button
          *ngIf="isFolded()"
          [icon]="menuItem.icon"
          [rounded]="true"
          [text]="true"
          size="small"
          severity="secondary"
          (click)="menuItem.command()"></p-button>
      }
    </div>
    <p-button
      (click)="toggleFold()"
      [icon]="isFolded() ? 'pi pi-angle-right' : 'pi pi-angle-left'"
      [rounded]="true"
      [text]="isFolded()"
      [raised]="!isFolded()"
      size="small"
      severity="primary"></p-button>
  </div>
</div>
