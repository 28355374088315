import { inject, Injectable } from '@angular/core';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppNewVersionService {
  #swUpdate: SwUpdate = inject(SwUpdate);
  #newVersionSubscription: Subscription | undefined;
  #confirmationService: ConfirmationService = inject(ConfirmationService);
  #translateService: TranslateService = inject(TranslateService);

  checkForUpdate(): void {
    this.#newVersionSubscription?.unsubscribe();
    if (!this.#swUpdate.isEnabled) {
      return;
    }
    this.#newVersionSubscription = this.#swUpdate.versionUpdates.subscribe((evt: VersionEvent) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt}`);
          console.log(`New app version ready for use: ${evt}`);
          this.#versionReadyHandler();
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version ${evt}`);
          break;
      }
    });
  }

  #versionReadyHandler(): void {
    this.#translateService
      .get(['swUpdate.header', 'swUpdate.message', 'swUpdate.acceptLabel', 'swUpdate.rejectLabel'])
      .subscribe((transl: Record<string, string>) => {
        this.#confirmationService.confirm({
          header: transl['swUpdate.header'],
          message: transl['swUpdate.message'],
          accept: () => {
            this.#swUpdate.activateUpdate().then(() => document.location.reload());
          },
          reject: () => {},
          acceptLabel: transl['swUpdate.acceptLabel'],
          rejectLabel: transl['swUpdate.rejectLabel'],
          icon: 'pi pi-exclamation-triangle',
        });
      });
  }
}
