export const environment = {
    adminRootUrl: `https://dev-fizjopro-admin-api-4ojp7omt6a-uc.a.run.app`,
    production: true,
    rootUrl: `https://dev-fizjopro-api-4ojp7omt6a-uc.a.run.app`,
    firebaseOptions: {
        apiKey: "AIzaSyBtzVqHQ2TDn6uIuL8WMTSGYZjIiatnM-0",
        appId: "1:851685634673:web:5a941ba3cb957f0c3c21d0",
        authDomain: "fizjopro.firebaseapp.com",
        measurementId: "G-962KS17SDJ",
        messagingSenderId: "851685634673",
        projectId: "fizjopro",
        storageBucket: "fizjopro.appspot.com"
    }
};
