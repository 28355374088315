<div>
  <div class="flex justify-content-center flex-column md:flex-row align-items-center md:align-items-start">
    <img class="mr-3" width="200px" src="/assets/no_resource.svg" />
    <div>
      <h3>{{ 'outOfResourcesDialog.header' | translate }}</h3>
      <p>{{ message }}</p>
    </div>
  </div>
</div>
<div class="flex justify-content-end gap-2 mt-2">
  <p-button
    [label]="'outOfResourcesDialog.cancelButtonLabel' | translate"
    severity="secondary"
    [outlined]="true"
    (click)="close()"></p-button>
  <p-button
    [label]="'outOfResourcesDialog.unlockButtonLabel' | translate"
    [routerLink]="'products'"
    (click)="close()"></p-button>
</div>
