import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal, WritableSignal } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ButtonModule } from 'primeng/button';

export type BottomToolbarItem = {
  icon?: string;
  command: () => void;
};

@Component({
  selector: 'fizjo-pro-bottom-toolbar',
  standalone: true,
  imports: [CommonModule, ButtonModule, RouterLink],
  templateUrl: './bottom-toolbar.component.html',
  styleUrl: './bottom-toolbar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomToolbarComponent {
  @Input() items: BottomToolbarItem[] = [];
  @Output() stateChange: EventEmitter<'fold' | 'unfold'> = new EventEmitter();
  isFolded: WritableSignal<boolean> = signal(false);

  toggleFold(): void {
    this.isFolded.set(!this.isFolded());
    this.stateChange.emit(this.isFolded() ? 'fold' : 'unfold');
  }
}
