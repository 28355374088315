<ng-container *ngIf="order">
  <div class="bg-white border-round flex flex-column order-dialog">
    <div class="flex flex-column w-full lg:flex-row align-items-start">
      <img
        [src]="'/assets/stripe-products/square/' + order.product.productCode + '.png'"
        class="hidden lg:inline-block lg:h-16rem"
        [alt]="order.product.name" />
      <img
        [src]="'/assets/stripe-products/' + order.product.productCode + '.png'"
        class="inline-block lg:hidden h-auto w-full"
        [alt]="order.product.name" />
      <div class="flex flex-column justify-content-between w-full align-self-stretch">
        <p-tabView class="flex-grow-1" styleClass="w-full">
          <p-tabPanel>
            <ng-template pTemplate="header"><label class="text-xl font-bold" translate>order.title</label></ng-template>
            <div
              class="h-10rem flex flex-column text-lg justify-content-center line-height-4 align-items-center"
              [innerHTML]="
                'order.summary.' +
                  (order.price.recurring ? 'recurring' : 'oneTime') +
                  '.' +
                  (isYearly ? 'year' : 'month')
                  | translate: { price: price(order.price) * order.quantity * 1.23 | currency: 'zł' }
              "></div>
          </p-tabPanel>
          <p-tabPanel>
            <ng-template pTemplate="header"
              ><label class="text-xl font-bold" translate>order.invoices</label></ng-template
            >
            <div class="flex flex-column justify-content-between">
              <div class="font-bold text-sm" translate>order.summary.invoiceData</div>
              <div class="text-sm flex flex-row justify-content-between">
                <div>
                  <span class="font-semibold">{{ billingData()?.name }}</span
                  ><br />
                  <span *ngIf="billingData()?.address?.line1 as line1">{{ line1 }},&nbsp;</span>
                  <span *ngIf="billingData()?.address?.line2 as line2">{{ line2 }},&nbsp;</span>
                  <span *ngIf="billingData()?.address?.postal_code as postalCode" class="white-space-nowrap"
                    >{{ postalCode }}&nbsp;</span
                  >
                  <span *ngIf="billingData()?.address?.city as city">{{ city }}</span
                  ><br />
                  <span *ngIf="vat() as vat">{{ 'order.summary.vatLabel' | translate }}&nbsp;{{ vat }}</span>
                </div>
                <p-button
                  icon="pi pi-pencil"
                  [pTooltip]="'edit' | translate"
                  [rounded]="true"
                  [text]="true"
                  (click)="editBillingData()"></p-button>
              </div>
              <p-table [value]="[order]" styleClass="p-datatable-sm w-full">
                <ng-template pTemplate="header">
                  <tr class="text-xs font-semibold">
                    <th translate>order.productName</th>
                    <th translate>order.amount</th>
                    <th translate>order.net</th>
                    <th translate>order.totalPrice</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-orderItem>
                  <tr class="text-xs">
                    <td>{{ orderItem.product.name }}</td>
                    <td>{{ orderItem.quantity }}</td>
                    <td>{{ price(orderItem.price) | currency: 'zł' }}</td>
                    <td>{{ price(orderItem.price) * orderItem.quantity * 1.23 | currency: 'zł' }}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
    <div class="px-3 flex flex-column gap-2 align-content-start pt-3">
      <div class="text-xs">
        <p translate>order.summary.disclaimer.0</p>
        <p translate>order.summary.disclaimer.1</p>
        <p translate class="font-semibold">payments.disclaimer.0</p>
        <p translate>payments.disclaimer.1</p>
      </div>
      <span class="flex flex-row align-items-center gap-3"
        ><p-checkbox [formControl]="acceptRulesCtrl" [binary]="true" inputId="acceptRulesCtrl"></p-checkbox>
        <label for="acceptRulesCtrl" translate>order.summary.acceptRulesCtrl</label></span
      >
      <p-message
        styleClass="w-full"
        severity="danger"
        *ngIf="!hasBillingAddress()"
        [text]="'billingData.noBillingAddress' | translate"></p-message>
    </div>
    <div class="flex flex-column gap-3 lg:gap-0 lg:flex-row lg:justify-content-between p-3">
      <div class="flex flex-row gap-3">
        <p-button
          styleClass="w-full lg:auto"
          severity="secondary"
          (click)="close()"
          [outlined]="true"
          [label]="'order.cancel' | translate"></p-button>
        @if (hasOrder()) {
          <p-button
            [label]="'order.summary.clearBasket' | translate"
            (click)="clearAndClose()"
            [outlined]="true"
            severity="info"></p-button>
        }
      </div>
      <p-button
        styleClass="w-full lg:auto"
        [disabled]="disableCheckoutButton() || !hasBillingAddress()"
        [label]="'order.buy' | translate"
        (click)="checkout()"></p-button>
    </div>
  </div>
</ng-container>
